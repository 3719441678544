import React, { useState } from 'react';
import Image from 'next/image';
import cn from 'classnames';
import * as Yup from 'yup';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import PersonalService from '@services/PersonalService';

import { useLocale } from '@hooks/useLocale';

import styles from './NewsletterSignupBlock.module.scss';
import { NewsletterSignupBlockValues } from './NewsletterSignupBlock.props';
import CheckboxInput from '@components/ui/CheckboxInput/CheckboxInput';
import InputWithSubmit from '@components/ui/InputWithSubmit/InputWithSubmit';
import TextLink from '@components/ui/TextLink/TextLink';

const NewsletterSignupBlock = () => {
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setLoading] = useState(false);
  const locale = useLocale();

  const validationScheme = Yup.object().shape({
    email: Yup.string()
      .email(t('VALIDATION.E_MAIL_INVALID'))
      .required(t('VALIDATION.FIELD_REQUIRED')),
    newsletterSignupBlockTerms: Yup.boolean().isTrue(t('VALIDATION.MUST_ACCEPT_TERMS')),
  });

  const handleSubmit = async (values: NewsletterSignupBlockValues) => {
    setError('');
    setMessage('');

    setLoading(true);

    try {
      await PersonalService.newsletterSubscribe(values.email);
      setMessage(t('DOMAIN.NEWSLETTER.SUBSCRIPTION_SUCCESS'));
    } catch (e) {
      setError(t('DOMAIN.NEWSLETTER.SUBSCRIPTION_ERROR'));
    } finally {
      setLoading(false);
    }
  };

  const getPrivacyStatementUrl = () => {
    // tODO: Make translatable
    return locale === 'nl-NL' ? '/privacy-verklaring' : '/privacy-statement';
  };

  const getTermsUrl = () => {
    // tODO: Make translatable

    return locale === 'nl-NL' ? '/algemene-voorwaarden' : '/terms-and-conditions';
  };

  return (
    <div className={styles.NewsletterSignupBlock}>
      <div className={styles.BackgroundImage}>
        <Image
          src={require('@assets/images/newslettersignupbackground.png')}
          alt=""
          quality={60}
          layout="fill"
        />
      </div>
      <div className={styles.AccentImage}>
        <div className={styles.ImageWrapper}>
          <Image
            src={require('@assets/images/newslettersignupimage.jpg')}
            alt=""
            quality={60}
            layout="fill"
          />
        </div>
      </div>
      <div className={styles.Content}>
        <h2 className={cn(styles.Title, 'h1 h1--large')}>
          {t('COMPONENTS.NEWSLETTER_SIGNUP_BLOCK.TITLE')}
        </h2>
        <p className={styles.Description}>{t('COMPONENTS.NEWSLETTER_SIGNUP_BLOCK.DESCRIPTION')}</p>
        <Formik
          initialValues={{ email: '', newsletterSignupBlockTerms: false }}
          onSubmit={handleSubmit}
          validationSchema={validationScheme}>
          {({ handleSubmit, errors, touched }) => {
            let errorMessage = error;

            if (touched.email && errors.email) errorMessage = errors.email;
            if (touched.newsletterSignupBlockTerms && errors.newsletterSignupBlockTerms)
              errorMessage = errors.newsletterSignupBlockTerms;

            return (
              <Form>
                <Field name="email">
                  {({ field }: FieldProps) => (
                    <InputWithSubmit
                      type="email"
                      placeholder={t('DOMAIN.NEWSLETTER.EMAIL')}
                      submitLabel={t('DOMAIN.NEWSLETTER.SIGNUP')}
                      onSubmit={handleSubmit}
                      isLoading={isLoading}
                      className={styles.Email}
                      data-testid="EmailInput"
                      {...field}
                    />
                  )}
                </Field>
                <p
                  className={cn(styles.Error, {
                    [styles.Show]: !!errorMessage,
                  })}
                  data-testid="ErrorMessage">
                  {errorMessage}
                </p>
                <p
                  className={cn(styles.Message, {
                    [styles.Show]: !!message,
                  })}
                  data-testid="Message">
                  {message}
                </p>
                <Field name="newsletterSignupBlockTerms">
                  {({ field }: FieldProps) => (
                    <CheckboxInput className={styles.Checkbox} data-testid="AcceptTerms" {...field}>
                      {t('GENERIC.I_ACCEPT')}{' '}
                      <TextLink theme="basic" href={getTermsUrl()} openInNewTab>
                        {t('GENERIC.TERMS_AND_CONDITIONS')}
                      </TextLink>{' '}
                      {t('GENERIC.AND')}{' '}
                      <TextLink theme="basic" href={getPrivacyStatementUrl()} openInNewTab>
                        {t('GENERIC.PRIVACY_STATEMENT')}
                      </TextLink>
                    </CheckboxInput>
                  )}
                </Field>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default NewsletterSignupBlock;
