import React from 'react';
import Image from 'next/image';
import cn from 'classnames';
import { useTranslation } from 'react-i18next/';
import { faEye } from '@fortawesome/pro-light-svg-icons';

import Card from '../Card/Card';
import { useLocale } from '@hooks/useLocale';
import ArticleProperty from '../ArticleProperty/ArticleProperty';

import styles from './HighlightedNewsListItem.module.scss';
import Props from './HighlightedNewsListItem.props';

const HighlightedNewsListItem = ({ title, thumbnail, viewcount, url, className }: Props) => {
  const locale = useLocale();
  const { t } = useTranslation();

  return (
    <Card href={url} className={className}>
      <article className={styles.HighlightedNewsListItem}>
        <div className={styles.Thumbnail}>
          <Image src={thumbnail} alt="" quality={60} layout="fill" />
        </div>
        <div className={styles.Content}>
          <h3 className={cn('h1', styles.Title)}>{title}</h3>
          <ArticleProperty
            icon={faEye}
            label={t('DOMAIN.NEWS.VIEWCOUNT', {
              viewcount: parseInt(viewcount).toLocaleString(locale),
            })}
          />
        </div>
      </article>
    </Card>
  );
};

export default HighlightedNewsListItem;
