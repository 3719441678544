import React, { useEffect, useRef } from 'react';
import { ADVERTISEMENT_SITE, ADVERTISEMENT_TYPE } from '@constants/enums';
import { useLocale } from '@hooks/useLocale';

class AdvertisementUtil {
  public selectAdvertisement = (advertisementType: ADVERTISEMENT_TYPE) => {
    const locale = useLocale();
    const advertisementSite = locale === 'nl-NL' ? ADVERTISEMENT_SITE.DP : ADVERTISEMENT_SITE.AP;
    const ref = useRef<HTMLModElement>(null);

    useEffect(() => {
      if (window.adsbygoogle?.loaded) {
        try {
          window.adsbygoogle?.push({});
        } catch {}
      }
    }, [ref.current]);

    let textAlign, slot, layout, format;

    switch (advertisementType) {
      case ADVERTISEMENT_TYPE.ARTICLE:
        layout = 'in-article';
        format = 'fluid';
        textAlign = 'center' as const;
        break;
      case ADVERTISEMENT_TYPE.SIDEBAR:
        format = 'auto';
        break;
    }

    switch (advertisementType | advertisementSite) {
      case ADVERTISEMENT_TYPE.ARTICLE | ADVERTISEMENT_SITE.DP:
        slot = '8675886898';
        break;
      case ADVERTISEMENT_TYPE.ARTICLE | ADVERTISEMENT_SITE.AP:
        slot = '1256631683';
        break;

      case ADVERTISEMENT_TYPE.SIDEBAR | ADVERTISEMENT_SITE.DP:
        slot = '3630857048';
        break;

      case ADVERTISEMENT_TYPE.SIDEBAR | ADVERTISEMENT_SITE.AP:
        slot = '7884445755';
        break;
    }

    return (
      <ins
        ref={ref}
        className="adsbygoogle"
        style={{ display: 'block', textAlign }}
        data-adtest={process.env.NODE_ENV === 'development' ? 'on' : undefined}
        data-ad-layout={layout}
        data-ad-format={format}
        data-ad-client="ca-pub-8656564782464834"
        data-ad-slot={slot}
      />
    );
  };
}

export default new AdvertisementUtil();
