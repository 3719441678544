import React from 'react';
import Image from 'next/image';
import cn from 'classnames';
import { useTranslation } from 'react-i18next/';
import { faEye } from '@fortawesome/pro-light-svg-icons';

import { useLocale } from '@hooks/useLocale';
import ArticleProperty from '../ArticleProperty/ArticleProperty';
import Card from '../Card/Card';

import styles from './NewsListItem.module.scss';
import Props from './NewsListItem.props';

const NewsListItem = ({ title, thumbnail, viewcount, url, size = 'sm', className }: Props) => {
  const { t } = useTranslation();
  const locale = useLocale();

  return (
    <Card href={url} className={className}>
      <article className={cn(styles.NewsListItem, styles[size])}>
        {!!thumbnail && (
          <div className={styles.Thumbnail}>
            <Image src={thumbnail} alt="" quality={60} layout="fill" />
          </div>
        )}
        <div className={styles.Content}>
          <h3 className={cn('h1', styles.Title)}>{title}</h3>
          <ArticleProperty
            icon={faEye}
            label={t('DOMAIN.NEWS.VIEWCOUNT', {
              viewcount: parseInt(viewcount).toLocaleString(locale),
            })}
            size={size === 'md' ? 'sm' : 'xs'}
          />
        </div>
      </article>
    </Card>
  );
};

export default NewsListItem;
