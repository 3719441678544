import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next/';

import { ListWrapper } from '@components/containers';

import styles from './HighlightedNewsList.module.scss';
import Props from './HighlightedNewsList.props';
import HighlightedNewsItem from '@components/ui/HighlightedNewsItem/HighlightedNewsItem';
import HighlightedNewsListItem from '@components/ui/HighlightedNewsListItem/HighlightedNewsListItem';

const NoArticlesMessage = ({ message }: { message: string }) => (
  <p className={styles.NoArticles} data-testid="NoArticlesMessage">
    {message}
  </p>
);

const Articles = ({ articles }: Partial<Props>) => {
  if (articles) {
    return (
      <>
        <div className={cn(styles.HighlightedItemWrapper, 'col-12 col-xl-8')}>
          <HighlightedNewsItem {...articles[0]} className={styles.HighlightedItem} />
        </div>
        <div className={cn(styles.ListItems, 'col-12 col-xl-4')}>
          <div className="row">
            {articles.slice(1, 5).map((article, idx) => (
              <div className="col-12 col-md-6 col-xl-12" key={article.title + article.url + idx}>
                <HighlightedNewsListItem {...article} className={styles.NewsListItem} />
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }

  return null;
};

const HighlightedNewsList = ({ articles, title, link }: Props) => {
  const { t } = useTranslation();
  return (
    <ListWrapper
      icon={require('@assets/images/icons/thumbs-up.svg')}
      title={title || t('DOMAIN.NEWS.NEWS')}
      link={link}
      data-id="news-archive"
      size="lg">
      <div className={cn(styles.HighlightedNewsList, 'row')}>
        {articles?.length > 0 ? (
          <Articles articles={articles} />
        ) : (
          <NoArticlesMessage message={t('COMPONENTS.HIGHLIGHTED_NEWS_LIST.NO_ARTICLES')} />
        )}
      </div>
    </ListWrapper>
  );
};

export default HighlightedNewsList;
