import React from 'react';
import { useTranslation } from 'react-i18next/';

import { ListWrapper } from '@components/containers';
import NewsListItem from '@components/ui/NewsListItem/NewsListItem';

import styles from './NewsList.module.scss';
import Props from './NewsList.props';

const NoArticlesMessage = ({ message }: { message: string }) => (
  <p className={styles.NoArticles} data-testid="NoArticlesMessage">
    {message}
  </p>
);

const Articles = ({ articles }: Partial<Props>) => {
  if (articles) {
    return (
      <>
        {articles.map((article, idx) => (
          <NewsListItem
            {...article}
            className={styles.NewsListItem}
            key={article.title + article.url + idx}
            size="sm"
          />
        ))}
      </>
    );
  }

  return null;
};

const NewsList = ({ articles, ...wrapperProps }: Props) => {
  const { t } = useTranslation();

  return (
    <ListWrapper {...wrapperProps}>
      <div className={styles.NewsList}>
        {articles.length > 0 ? (
          <Articles articles={articles} />
        ) : (
          <NoArticlesMessage message={t('COMPONENTS.NEWS_LIST.NO_ARTICLES')} />
        )}
      </div>
    </ListWrapper>
  );
};

export default NewsList;
