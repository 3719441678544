import React, { RefObject } from 'react';
import cn from 'classnames';

import Button from '../Button/Button';

import styles from './InputWithSubmit.module.scss';
import Props from './InputWithSubmit.props';

const InputWithSubmit = (
  { type, placeholder, submitLabel, onSubmit, className, isLoading, ...otherProps }: Props,
  ref: any,
) => {
  return (
    <div className={cn(styles.InputWithSubmit, className)}>
      <input ref={ref} type={type} placeholder={placeholder} {...otherProps} />
      <Button
        type="submit"
        size="sm"
        className={styles.Submit}
        onPress={onSubmit}
        isLoading={isLoading}>
        {submitLabel}
      </Button>
    </div>
  );
};

export default React.forwardRef<RefObject<typeof InputWithSubmit>, Props>(InputWithSubmit);
