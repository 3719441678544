import React from 'react';
import Image from 'next/image';
import { useTranslation } from 'react-i18next/';
import { faEye } from '@fortawesome/pro-light-svg-icons';
import cn from 'classnames';

import { useLocale } from '@hooks/useLocale';
import ArticleProperty from '../ArticleProperty/ArticleProperty';
import Card from '../Card/Card';

import styles from './HighlightedNewsItem.module.scss';
import Props from './HighlightedNewsItem.props';

const HighlightedNewsItem = ({ title, thumbnail, viewcount, url, className }: Props) => {
  const { t } = useTranslation();
  const locale = useLocale();

  return (
    <Card href={url} className={className} innerClassName={styles.InnerClass}>
      <article className={styles.HighlightedNewsItem}>
        <Image
          src={thumbnail}
          alt=""
          quality={60}
          layout="fill"
          className={styles.BackgroundImage}
        />
        <div className={styles.ContentWrapper}>
          <div className={styles.Content}>
            <h3 className={cn('h1', styles.Title)}>{title}</h3>
            <ArticleProperty
              icon={faEye}
              label={t('DOMAIN.NEWS.VIEWCOUNT', {
                viewcount: parseInt(viewcount).toLocaleString(locale),
              })}
              theme="secondary"
            />
          </div>
        </div>
      </article>
    </Card>
  );
};

export default HighlightedNewsItem;
