import React from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import classes from './ArticleProperty.module.scss';
import Props from './ArticleProperty.props';

const ArticleProperty = ({ icon, label, theme = 'primary', size = 'xs' }: Props) => {
  return (
    <p className={cn(classes.ArticleProperty, classes[theme], classes[size])}>
      <FontAwesomeIcon icon={icon} className={classes.Icon} />
      {label}
    </p>
  );
};

export default ArticleProperty;
