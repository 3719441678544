const routes: any = {
  'nl-NL': {
    TRAVELSTORY_ARCHIVE: '/reisverhalen',
    DESTINATIONS: '/bestemmingen',
    ANIMALS: '/dieren-per-continent',
    LOGIN: '/inloggen',
    ABOUT: '/over-ons',
    PRIVACY: '/privacy-verklaring',
    TERMS: '/algemene-voorwaarden',
    COOKIES: '/cookie-beleid',
    PARTNERS: '/samenwerken-met-droomplekken',
    NEWS: '/nieuws',
    SET_PASSWORD: 'wachtwoord-instellen',
  },
  'en-GB': {
    TRAVELSTORY_ARCHIVE: '/travel-stories',
    DESTINATIONS: '/destinations',
    ANIMALS: '/wildlife-locations',
    LOGIN: '/login',
    ABOUT: '/about-us',
    PRIVACY: '/privacy-statement',
    TERMS: '/terms-and-conditions',
    COOKIES: '/cookie-policy',
    PARTNERS: '/work-with-us',
    NEWS: '/news',
    SET_PASSWORD: 'set-password',
  },
};

export const getRoute = (locale: string, key: keyof typeof routes) => {
  return routes[locale][key];
};
