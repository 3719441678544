import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import AdvertisementUtil from '@utils/AdvertisementUtil';
import Props from './Advertisement.props';
import { ADVERTISEMENT_DEVICE } from '@constants/enums';
import { useIsMobile } from '@hooks/useIsMobile';

import styles from './Advertisement.module.scss';
import { useRouter } from 'next/router';

export const Advertisement = ({
  advertisementType,
  advertisementDevice = ADVERTISEMENT_DEVICE.DESKTOP_AND_MOBILE,
  className,
}: Props) => {
  const { asPath } = useRouter();
  const [delayedPath, setDelayedPath] = useState('');
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (asPath === delayedPath) return;

    const timeout = setTimeout(() => setDelayedPath(asPath), 10);

    return () => clearTimeout(timeout);
  }, [asPath]);

  useEffect(() => {
    try {
      if (window.adsbygoogle?.loaded && asPath === delayedPath) {
        ref.current?.querySelectorAll('ins').forEach(() => window.adsbygoogle?.push({}));
      }
    } catch (_) {}
  }, [ref.current, asPath, delayedPath]);

  const isMobile = useIsMobile();
  const advertisement = AdvertisementUtil.selectAdvertisement(advertisementType);

  if (
    asPath !== delayedPath ||
    (advertisementDevice === ADVERTISEMENT_DEVICE.MOBILE && !isMobile) ||
    (advertisementDevice === ADVERTISEMENT_DEVICE.DESKTOP && isMobile)
  ) {
    return null;
  }

  return (
    <div ref={ref} className={cn(styles.Advertisement, className)}>
      {advertisement}
    </div>
  );
};
